<template>
  <div class="page-layout tx-map">
    <iframe
      id="mapPage"
      width="100%"
      height="100%"
      frameborder="0"
      :src="
        'https://apis.map.qq.com/tools/locpicker?search=1&type=1&key=VDEBZ-O22HK-DHHJU-AXDG5-AJGDZ-WXFEJ&referer=myapp&coord=' +
        lat +
        ',' +
        lon
      "
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: "mapSearch",
  props: ["cityName", "lat", "lon"],
  data() {
    return {
      searchValue: "",
      loading: false,
      finished: false,
    };
  },
  methods: {
    onLoad() {
      this.loading = true;
    },
    fun() {
      this.$emit("endSearch");
      window.removeEventListener("popstate", this.fun, false);
    },
  },
  mounted() {
    let _that = this;
    window.addEventListener(
      "message",
      function (event) {
        // 接收位置信息，用户选择确认位置点后选点组件会触发该事件，回传用户的位置信息
        var loc = event.data;
        if (loc && loc.module == "locationPicker") {
          //防止其他应用也会向该页面post信息，需判断module是否为'locationPicker'
          _that.$emit("setDetailAddress", loc);
        }
      },
      false
    );

    //监听安卓返回键
    if (window.history && window.history.pushState) {
      history.pushState(null, null, document.URL);
      window.addEventListener("popstate", this.fun, false); //false阻止默认事件
    }
  },
};
</script>

<style lang="less" scoped>
.page-layout {
  position: fixed;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  width: 100%;
  height: 100vh;
}
// .map-box {
//   flex: 60%;
//   width: 100%;
//   background-color: rgb(24, 165, 221);
// }
// .list-layout {
//   position: relative;
//   width: 100%;
//   flex: 40%;
//   overflow: auto;
//   box-sizing: border-box;
//   background-color: #fff;
//   .van-search {
//     position: fixed;
//     right: 0;
//     left: 0;
//   }
//   .address-ul {
//     padding-top: 54px !important;
//     .address-li {
//       padding: 12px 0;
//     }
//     padding: 0 12px;
//     .address-name {
//       font-size: 16px;
//       font-weight: 600;
//     }
//     .list {
//       margin-top: 6px;
//       font-size: 12px;
//       color: #c6c6c6;
//     }
//   }
// }
</style>
