<template>
  <div class="layout-big-box">
    <NavBar class="nav-bar" :bool="true" @navCallback="navCallback">
      <div slot="title">{{ showEdit ? "编辑收货地址" : "地址列表" }}</div>
    </NavBar>
    <div v-if="!showEdit">
      <!-- 导航栏 -->
      <RadioBox
        :isRadio="isRadio"
        ref="address"
        @editAddress="editAddress"
        @setAddress="setAddress"
        @setBlank="setBlank"
      ></RadioBox>
      <div class="address-content" v-if="false">
        <div class="address-layout" v-if="list.length > 0">
          <div
            class="address-card"
            v-for="(item, index) in list"
            :key="index"
            @click="setSelect(item)"
          >
            <div>
              <div class="user-contact">
                <span class="user-name">{{ item.consignee }}</span>
                <span class="user-mobile">{{ item.phone }}</span>
              </div>
              <div class="address-text">
                <span class="address">
                  {{ item.city_names }} {{ item.address }}
                </span>
                <van-icon
                  name="edit"
                  size="20"
                  @click.stop
                  @click="setEdit(item.id)"
                />
              </div>
              <div class="operation-button" @click.stop>
                <div @click.stop>
                  <van-checkbox
                    v-model="item.is_default"
                    @click="defaultAddress(item.id, index)"
                    @click.stop
                    checked-color="#ee0a24"
                  >
                    设为默认地址
                  </van-checkbox>
                </div>
                <span @click="deleteBtn(index)">删除</span>
              </div>
            </div>
          </div>
        </div>
        <PromptPopup :showPopup="showPopup" @popupFn="popupFn"></PromptPopup>
      </div>
      <van-empty description="去添加收货地址吧" v-if="emptyShow" />
      <div class="button-box">
        <van-button round block type="danger" @click="setEdit(0)">
          新增地址
        </van-button>
      </div>
    </div>
    <div v-else>
      <!-- 编辑组件-->
      <AddressUpdate
        :editId="editId"
        @setEditAddress="setEditAddress"
        @closePage="showEdit = false"
      ></AddressUpdate>
    </div>
  </div>
</template>

<script>
import NavBar from "@/layout/nav-bar/nav-bar";
import RadioBox from "./components/radio/radio-box";
import { getAddressList, defaultAddress, delAddress } from "@/api/member";
import AddressUpdate from "./components/address-update/address-update";
import { Toast } from "vant";
import PromptPopup from "@/components/prompt-popup/prompt-popup";
export default {
  name: "address-list",
  components: {
    NavBar,
    AddressUpdate,
    PromptPopup,
    RadioBox,
  },
  props: {
    isRadio: {
      default: false,
    },
    orderType: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isClose: this.$route.query.isClose,
      checked: "checkbox",
      showPopup: false,
      radio: 1,
      list: [],
      emptyShow: false,
      shoppingIds:
        this.$route.query.shoppingIds === undefined
          ? ""
          : this.$route.query.shoppingIds,
      delIndex: -1,
      //编辑主键显示信息
      showEdit: false,
      editId: 0,
    };
  },
  mounted() {
    // this.getAddressList();
    if (this.orderType * 1 > 0) {
      //监听安卓返回键
      if (window.history && window.history.pushState) {
        history.pushState(null, null, document.URL);
        window.addEventListener("popstate", this.fun, false); //false阻止默认事件
      }
    }
  },
  methods: {
    fun() {
      this.$emit("closePage");
      window.removeEventListener("popstate", this.fun, false);
    },
    navCallback() {
      if (this.showEdit) {
        this.showEdit = false;
      } else {
        this.showEdit = true;
        this.$router.go(-1);
      }
      // if (this.isClose) {
      //   this.$router.go(-1);
      //   this.isClose = false;
      // } else {
      //   this.$emit("closePage");
      // }
    },
    addressRadio(id) {
      this.$refs.address.setRadioId(id);
    },
    setBlank(bool) {
      this.emptyShow = bool;
    },
    setAddress(obj) {
      this.$emit("setAddressInfo", obj);
    },
    setEdit(editId) {
      this.showEdit = true;
      this.editId = editId;
    },
    setEditAddress(id) {
      // this.getAddressList();
      this.showEdit = false;
      this.$nextTick(() => {
        this.$refs.address.getAddressList();
      });
      this.editId = -1;
    },
    deleteBtn(delIndex) {
      this.showPopup = true;
      this.delIndex = delIndex;
    },
    cancelBtn() {
      this.showPopup = false;
    },
    // async popupFn(bol) {
    //   // 删除确认时做些什么
    //   if (bol) {
    //     if (this.delIndex < 0) {
    //       Toast.fail("删除信息异常");
    //       return false;
    //     }
    //     const ret = await delAddress({ id: this.list[this.delIndex].id });
    //     if (ret.code * 1 === 1) {
    //       this.list.splice(this.delIndex, 1);
    //     }
    //   }
    //   this.showPopup = false;
    // },
    updateAddress(addressId) {
      this.$router.push({
        name: "address-update",
        query: {
          shoppingIds: this.shoppingIds,
          orderType: this.orderType,
          addressId: addressId,
        },
      });
    },
    editAddress(addressId) {
      this.showEdit = true;
      this.editId = addressId;
    },
    // async defaultAddress(addressId, index) {
    //   //设置默认收货地址
    //   const ret = await defaultAddress({ id: addressId });
    //   if (ret.code * 1 === 1) {
    //     if (ret.data.is_default * 1 === 0) this.list[index].is_default = 0;
    //     this.list.map((item, key) => {
    //       if (index !== key) {
    //         this.list[key].is_default = 0;
    //       }
    //     });
    //     if (this.orderType * 1 === 0) {
    //       this.$emit("closePage");
    //     }
    //     Toast.success("设置成功");
    //     this.getAddressList();
    //   }
    // },

    // async getAddressList() {
    //   const ret = await getAddressList();
    //   if (ret.code * 1 === 1) {
    //     this.list = ret.data;
    //     this.list.forEach((item) => {
    //       if (item.is_default === 1) {
    //         this.radio = item.is_default;
    //       }
    //     });
    //   }
    // },
    setSelect(a_info) {
      if (this.orderType * 1 === 0) {
        return false;
      }
      this.$emit("setAddressInfo", a_info);
    },
  },
};
</script>

<style lang="less" scoped>
.nav-bar {
  background-color: #fff;
  border-bottom: 1px solid #f5f5f5;
}
.van-empty {
  padding: 80px 0;
}
.layout-big-box {
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1000;
  overflow: auto;
  background-color: #f9f9f9;
}
.address-layout {
  padding: 50px 12px 12px 12px;
}

.address-card {
  padding: 12px;
  width: 100%;
  background-color: #fff;
  border-radius: 16px;
  box-sizing: border-box;

  .user-contact {
    .user-name {
      font-size: 18px;
      font-weight: 500;
    }

    .user-mobile {
      padding-left: 12px;
      color: #b9babe;
      font-size: 16px;
    }
  }

  .address-text {
    display: flex;
    justify-content: space-between;
    .address {
      padding: 4px 0 20px 0;
      flex: 80%;
      font-size: 16px;
    }
    .van-icon {
      display: flex;
      justify-content: flex-end;
      flex: 20%;
    }
  }

  .operation-button {
    display: flex;
    justify-content: space-between;
    color: #b9babe;
  }
}

.set-default {
  padding-left: 10px;
}

// 弹出层
.van-popup {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 30px 14px;
  width: 320px;
  height: 190px;
  border-radius: 16px;
  box-sizing: border-box;

  .is-button {
    display: flex;
    justify-content: space-around;
    width: 100%;

    .button {
      width: 116px;
    }
  }
}
.button-box {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: 4px 13px;
  background-color: #fff;
}
//复选框样式
.tui-checkbox:checked {
  background: #1673ff;
}

.tui-checkbox {
  width: 25px;
  height: 25px;
  background-color: #ffffff;
  border: solid 1px #dddddd;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  font-size: 0.8rem;
  margin: 0;
  padding: 0;
  position: relative;
  display: inline-block;
  vertical-align: top;
  cursor: default;
  -webkit-appearance: none;
  -webkit-user-select: none;
  user-select: none;
  -webkit-transition: background-color ease 0.1s;
  transition: background-color ease 0.1s;
}

.tui-checkbox:checked::after {
  content: "";
  top: 5px;
  left: 5px;
  position: absolute;
  background: transparent;
  border: #fff solid 2px;
  border-top: none;
  border-right: none;
  height: 6px;
  width: 10px;
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
.van-checkbox__label {
  font-size: 14px;
}
</style>
