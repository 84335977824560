<template>
  <div>
    <!-- <NavBar :bool="true" @navCallback="navCallback">
      <div slot="title">编辑地址</div>
    </NavBar> -->
    <div class="layout">
      <div class="input-box">
        <div class="lable-title">收货人</div>
        <input
          type="text"
          placeholder="请输入姓名"
          v-model="addressInfo.consignee"
          class="input"
        />
        <div class="clear-input" v-show="clearUser" @click="clearText(1)">
          <van-icon name="cross" size="12" color="#000" class="icon-cross" />
        </div>
      </div>
      <div class="input-box">
        <div class="lable-title">电话</div>
        <input
          type="number"
          placeholder="请输入手机号"
          v-model="addressInfo.phone"
          class="input"
        />
        <div class="clear-input" v-show="clearPhone" @click="clearText(2)">
          <van-icon name="cross" size="12" color="#000" class="icon-cross" />
        </div>
      </div>
      <div class="input-box" >
        <div class="lable-title" @click="showPopup = true">选择地区</div>
        <div class="region-info" @click="showPopup = true">
          {{ addressInfo.city_names }}
        </div>
        <van-icon
          @click="showPopup = true"
          name="arrow"
          class="select-region"
          size="18"
          color="#b9babe"
        />
      </div>

<!--      @click="showTxMap"-->
      <div class="input-box" >
        <div class="lable-title">地址详情</div>
        <div class="region-info">
<!--          {{ addressInfo.address }}-->
          <input
            type="text"
            placeholder="请输入详细地址"
            v-model="addressInfo.address"
            class="input"
          />
        </div>
        
      </div>



      <div class="default" @click="setDefault">
        <div class="default-address">默认为收货地址</div>
        <div
          class="radio-button"
          :class="{ 'radio-color': addressInfo.is_default * 1 === 1 }"
        >
          <van-icon
            name="success"
            color="#fff"
            size="18"
            class="icon-success"
          />
        </div>
      </div>
      <div class="del-button" v-if="addressInfo.id * 1 > 0" @click="deleteBtn">
        <div class="del">
          <van-icon name="delete-o" />
          <span>删除</span>
        </div>
      </div>
      <div class="save-btn">
        <van-button type="danger" round block @click="editAddress">
          保存
        </van-button>
      </div>
    </div>
    <van-popup v-model="showPopup" round position="bottom">
      <!-- 联级选择 -->
      <van-cascader
        v-model="cascaderValue"
        title="请选择所在地区"
        :options="options"
        :field-names="fieldNames"
        @finish="chooseComplete"
        @close="onClose"
      />
    </van-popup>

    <MapSearch
      :lat="lat"
      :lon="lon"
      v-if="showMap"
      @setDetailAddress="setDetailAddress"
      @endSearch="endSearch"
      class="map-search"
    ></MapSearch>
  </div>
</template>

<script>
import MapSearch from "@/components/map/mapSearch";

// import NavBar from "@/layout/nav-bar/nav-bar";
import { Toast, Dialog } from "vant";
import {
  delAddress,
  editAddress,
  getAddressInfo,
  defaultAddress,
} from "@/api/member";
import regionThree from "@/common/region/regionThree.json";
export default {
  name: "address-update",
  props: ["editId"],
  components: {
    // NavBar,
    MapSearch,
  },
  data() {
    return {
      lon: 106.55034,
      lat: 29.56208,
      showMap: false,


      searchResult: [],
      showPopup: false, // 弹出层控制
      fieldNames: {
        text: "text",
        value: "value",
        children: "children",
      },
      options: regionThree,
      cascaderValue: "",
      addressInfo: {
        address: "",
        area: 0,
        city: 0,
        city_names: "",
        consignee: "",
        id: 0,
        is_default: 0,
        phone: "",
        province: 0,
        lon: 106.553842,
        lat: 29.561557,
      },
      isAjax: false,
    };
  },
  mounted() {
    this.getAddressInfo();
  },
  computed: {
    clearUser() {
      if (this.addressInfo.consignee.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    clearPhone() {
      if (this.addressInfo.phone.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    clearAddress() {
      if (this.addressInfo.address.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    endSearch() {
      this.showMap = false;
    },
    showTxMap() {
      this.showMap = true;
    },
    setDetailAddress(location) {
      console.log(location);
      this.addressInfo.address = location.poiaddress + location.poiname;
      this.lon = location.latlng.lng;
      this.lat = location.latlng.lat;
      this.showMap = false;
    },



    navCallback() {
      this.$emit("closePage");
    },
    clearText(num) {
      // 清除输入框
      switch (num) {
        case 1:
          //收货人
          this.addressInfo.consignee = "";
          break;
        case 2:
          //电话
          this.addressInfo.phone = "";
          break;
        case 3:
          //详细地址
          this.addressInfo.address = "";
          break;

        default:
          break;
      }
    },
    async setDefault() {
      // 是否默认地址
      if (this.editId * 1 > 0) {
        const res = await defaultAddress({ id: this.editId });
        if (res.code * 1 === 1) {
          if (res.data.is_default * 1 === 1) {
            Toast.success("设置成功");
          } else {
            Toast.success("取消成功");
          }
          this.getAddressInfo();
        }
        return;
      }
      if (this.addressInfo.is_default === 1) {
        this.addressInfo.is_default = 0;
      } else {
        this.addressInfo.is_default = 1;
      }
    },
    deleteBtn() {
      //删除收货地址
      if (this.editId * 1 <= 0) {
        return;
      }
      Dialog.confirm({
        message: "是否确认删除该地址？",
      })
        .then(async () => {
          const res = await delAddress({ id: this.editId });
          if (res.code * 1 === 1) {
            Toast.success("删除成功");
            this.$emit("setEditAddress");
          }
        })
        .catch(() => {
          // on cancel
        });
    },
    chooseComplete(value) {
      // 地区选择
      this.addressInfo.province = value.selectedOptions[0].value;
      this.addressInfo.city = value.selectedOptions[1].value;
      this.addressInfo.area = value.selectedOptions[2].value;
      this.addressInfo.city_names =
        value.selectedOptions[0].text +
        "" +
        value.selectedOptions[1].text +
        "" +
        value.selectedOptions[2].text +
        "";
      this.showPopup = false;
    },
    async getAddressInfo() {
      // 获取地址信息
      if (this.editId * 1 > 0) {
        const ret = await getAddressInfo({ id: this.editId });
        if (ret.code * 1 === 1) {
          this.addressInfo = ret.data;
        }
      }
    },
    async editAddress() {
      //保存收货地址
      if (this.isAjax) {
        return true;
      }
      this.isAjax = true;
      if (this.validator()) {
        this.addressInfo.lat = this.lat;
        this.addressInfo.lon = this.lon;
        const ret = await editAddress(this.addressInfo);
        if (ret.code * 1 === 1) {
          Toast.success("保存成功");
          this.$emit("setEditAddress", this.addressInfo.id);
        }
      }
      this.isAjax = false;
    },
    validator() {
      let reg = /^(?:(?:\+|00)86)?1\d{10}$/;
      if (this.addressInfo.consignee === "") {
        Toast.fail("姓名不能为空");
        return false;
      } else if (this.addressInfo.phone === "") {
        Toast.fail("手机不能为空");
        return false;
      } else if (!reg.test(this.addressInfo.phone)) {
        Toast.fail("手机填写错误");
        return false;
      } else if (
        this.addressInfo.city_names === "" ||
        this.addressInfo.address === ""
      ) {
        Toast.fail("地址不能为空");
        return false;
      }
      return true;
    },
    onClose() {
      // 点击关闭图标时需要做什么
      this.showPopup = false;
    },
  },
};
</script>

<style lang="less" scoped>
.map-search {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 9999;
  }


.layout {
  padding-top: 53px;


  .input-box {
    position: relative;
    font-size: 15px;
    display: flex;
    padding: 13px 0;
    padding-left: 13px;
    border-bottom: 1px solid #ededed;
    background-color: #fff;
    .lable-title {
      width: 62px;
      height: 100%;
      margin-right: 18px;
    }
    .input {
      width: 276px;
      font-weight: 500;
      border: none;
    }
    .clear-input {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 17px;
      height: 17px;
      border-radius: 50%;
      background-color: #e0e0e0;
    }
    .select-region {
      position: absolute;
      top: 50%;
      right: 13px;
      transform: translateY(-50%);
    }
    .region-info {
      width: 276px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
  }
  .default {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 15px;
    display: flex;
    padding: 13px;
    background-color: #fff;
    border-bottom: 1px solid #ededed;
    .radio-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 24px;
      height: 24px;
      background-color: #e0e0e0;
      border-radius: 50%;
    }
    .radio-color {
      background-color: #ed301d;
    }
  }
  .del-button {
    display: flex;
    justify-content: center;
    padding: 13px 0;
    background-color: #fff;
    font-size: 18px;
    margin-top: 12px;
    color: #ff3406;
    .del {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 58px;
    }
    &:active {
      background-color: rgb(241, 241, 241);
    }
  }
  .save-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    padding: 4px 13px;
  }
}
.van-button__text {
  font-size: 18px;
}
input::input-placeholder {
  color: #aaa;
}
input::-webkit-input-placeholder {
  color: #aaa;
}
input::-moz-placeholder {
  color: #aaa;
}
input:-moz-placeholder {
  color: #aaa;
}
input::-ms-input-placeholder {
  color: #aaa;
}
</style>
