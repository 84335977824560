<template>
  <div class="radio-layout-box">
    <div
      class="radio-layout"
      :class="{ 'radio-layout-padding': !isRadio }"
      v-for="item in addressList"
      :key="item.id"
    >
      <div
        class="radio-button"
        :class="{
          'back-color': item.id === addressId,
        }"
        v-if="isRadio"
        @click="selectAddress(item.id)"
      >
        <van-icon name="success" color="#fff" size="16" class="icon-success" />
      </div>
      <div class="personage-info" @click="selectAddress(item.id)">
        <div class="user-info">
          <span class="name">{{ item.consignee }}</span>
          <span class="phone">{{ item.phone }}</span>
          <span class="default-tag" v-show="item.is_default === 1">默认</span>
        </div>
        <div class="address-info">
          <span>{{ item.city_names }}</span>
          <span>{{ item.address }}</span>
        </div>
      </div>
      <div class="edit-buttons" @click="editBtn(item.id)">
        <van-icon name="edit" size="20" color="#666666" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAddressList } from "@/api/member";
export default {
  name: "radio-box",
  props: {
    isRadio: {
      default: false,
    },
  },
  data() {
    return {
      addressId: 0,
      addressList: [],
    };
  },
  mounted() {
    this.addressId = localStorage.getItem("ADDRESS_ID") * 1;
    this.getAddressList();
  },
  methods: {
    editBtn(id) {
      this.$emit("editAddress", id);
    },
    setRadioId(id) {
      this.addressId = id;
    },
    selectAddress(id) {
      let data = [];
      this.addressId = id;
      data = this.addressList.filter((item) => item.id * 1 === id);
      if (data.length > 0) {
        this.$emit("setAddress", data[0]);
      }
    },
    async getAddressList() {
      const res = await getAddressList();
      if (res.code * 1 === 1) {
        this.addressList = res.data;
        if (res.data.length <= 0) {
          this.$emit("setBlank", true);
        } else {
          this.$emit("setBlank", false);
        }
        // data = res.data.filter((item) => item.is_default * 1 === 1);
        // if (data.length <= 0) {
        //   this.addressId = 0;
        // } else {
        //   this.addressId = data[0].id;
        // }
      }
    },
  },
};
</script>

<style lang="less" scoped>
.radio-layout-box {
  padding: 58px 0 54px 0;
}
.radio-layout {
  position: relative;
  height: 108px;
  padding: 14px 57px 11px 45px;
  margin: 0px 13px 11px 13px;
  background-color: #fff;
  border-radius: 11px;
  box-sizing: border-box;
  .radio-button {
    position: absolute;
    top: 50%;
    left: 13px;
    transform: translateY(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    background: #f5f5f5;
    border-radius: 50%;
  }
  .back-color {
    background-color: #ed301d;
  }
  .personage-info {
    height: 100%;
    font-size: 15px;
    color: #333;
    span {
      margin-right: 11px;
    }
    .user-info {
      .name {
        font-size: 18px;
      }
      .phone {
        color: #b9babe;
      }
      .default-tag {
        width: 35px;
        height: 18px;
        text-align: center;
        line-height: 18px;
        background: #ffffff;
        border: 1px solid #ed301d;
        color: #ed301d;
        border-radius: 4px;
        font-size: 12px;
        padding: 1px 7px;
      }
    }
    .address-info {
      margin-top: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      word-break: break-all;
    }
  }
  .edit-buttons {
    position: absolute;
    top: 50%;
    right: 13px;
    transform: translateY(-50%);
  }
}
.radio-layout-padding {
  padding-left: 13px;
}
</style>